
import { Component, Vue } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import {
  apiModelCategoryList,
  apiModelCategoryAdd,
  apiModelCategoryEdit,
  apiModelCategoryDetail,
} from "@/api/model.ts";
@Component({
  components: {
    MaterialSelect,
  },
})
export default class ModelCategoryEdit extends Vue {
  $refs!: { form: any };
  id!: any;
  loading = false;
  hasPid = 0;
  categoryList = [];
  form = {
    zh_cn_name: "",
    en_name: "",
    level: 0,
    pid: "",
    sort: 0,
    status: 1,
  };

  rules = {
    zh_cn_name: [
      {
        required: true,
        message: "请输入分类名称",
        trigger: ["blur", "change"],
      },
    ],
    en_name: [
      {
        required: true,
        message: "请输入英文分类名称",
        trigger: ["blur", "change"],
      },
    ],
  };

  handleSave() {
    this.$refs.form.validate((valid: boolean) => {
      if (valid) {
        if (!this.hasPid) {
          this.form.pid = "";
        }
        const api = this.id
          ? apiModelCategoryEdit(this.form)
          : apiModelCategoryAdd(this.form);
        api.then(() => {
          this.$router.go(-1);
        });
      } else {
        return false;
      }
    });
  }

  getCategoryDetail() {
    this.loading = true;
    apiModelCategoryDetail({ id: this.id }).then((res: any) => {
      if (res.pid) {
        this.hasPid = 1;
      }
      this.form = res;
      this.loading = false;
    });
  }

  getCategoryList() {
    apiModelCategoryList({ page_type: 1 }).then((res: any) => {
      res?.lists.forEach((item: any) => {
        item.sons &&
          item.sons.forEach((sitem: any) => {
            delete sitem.sons;
          });
      });
      this.categoryList = res?.lists;
      console.log(this.categoryList);
    });
  }

  created() {
    this.id = this.$route.query.id;
    this.id && this.getCategoryDetail();
    this.getCategoryList();
  }
}
